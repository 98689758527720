import { isObject } from '@guardian/libs';
import fastdom from '../../../../lib/fastdom-promise';
import { renderAdvertLabel, renderStickyScrollForMoreLabel, } from '../dfp/render-advert-label';
const isDCR = window.guardian.config.isDotcomRendering;
const getStylesFromSpec = (specs) => {
    const styles = { ...specs };
    delete styles.scrollType;
    delete styles.ctaUrl;
    // native templates are sometimes using the british spelling of background-color for some reason
    if (styles.backgroundColour) {
        styles.backgroundColor = styles.backgroundColour;
        delete styles.backgroundColour;
    }
    return styles;
};
const isBackgroundSpecs = (specs) => isObject(specs) && 'backgroundImage' in specs;
const createParent = (adSlot, scrollType) => {
    let backgroundParent = adSlot.querySelector('.creative__background-parent');
    let background = adSlot.querySelector('.creative__background');
    if (!backgroundParent || !background) {
        backgroundParent = document.createElement('div');
        background = document.createElement('div');
        backgroundParent.classList.add('creative__background-parent');
        background.classList.add('creative__background');
        if (scrollType) {
            backgroundParent.classList.add(`creative__background-parent--${scrollType}`);
            background.classList.add(`creative__background--${scrollType}`);
        }
        backgroundParent.appendChild(background);
        if (isDCR) {
            backgroundParent.style.zIndex = '-1';
            backgroundParent.style.position = 'absolute';
            backgroundParent.style.inset = '0';
            backgroundParent.style.clipPath =
                'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)';
            backgroundParent.style.overflow = 'hidden';
            background.style.inset = '0';
            background.style.transition = 'background 100ms ease';
        }
    }
    return { backgroundParent, background };
};
const setBackgroundStyles = (specs, background) => {
    const specStyles = getStylesFromSpec(specs);
    Object.assign(background.style, specStyles);
};
const setCtaURL = (ctaURL, backgroundParent) => {
    const ctaURLAnchor = document.createElement('a');
    ctaURLAnchor.href = ctaURL;
    ctaURLAnchor.target = '_new';
    ctaURLAnchor.appendChild(backgroundParent);
    ctaURLAnchor.style.width = '100%';
    ctaURLAnchor.style.height = '100%';
    ctaURLAnchor.style.display = 'inline-block';
    return ctaURLAnchor;
};
const renderBottomLine = (background, backgroundParent) => {
    background.style.position = 'fixed';
    const bottomLine = document.createElement('div');
    bottomLine.classList.add('ad-slot__line');
    bottomLine.style.position = 'absolute';
    bottomLine.style.width = '100%';
    bottomLine.style.bottom = '0';
    bottomLine.style.borderBottom = '1px solid #dcdcdc';
    backgroundParent.appendChild(bottomLine);
};
const setupParallax = (adSlot, background, backgroundParent) => {
    background.style.position = 'absolute';
    adSlot.style.position = 'relative';
    const onScroll = (background) => fastdom
        .measure(() => background.getBoundingClientRect())
        .then((rect) => fastdom.mutate(() => {
        const backgroundHeight = rect.height;
        const windowHeight = window.innerHeight;
        // we should scroll at a rate such that we don't run out of background (when non-repeating)
        const parallaxBackgroundMovement = Math.floor((rect.bottom / (windowHeight + backgroundHeight)) * 130);
        background.style.backgroundPositionY = `${parallaxBackgroundMovement}%`;
    }));
    const onIntersect = (entries) => entries
        .filter((entry) => entry.isIntersecting)
        .forEach(() => {
        window.addEventListener('scroll', () => void onScroll(background), {
            passive: true,
        });
        void onScroll(background);
    });
    const observer = new IntersectionObserver(onIntersect, {
        rootMargin: '10px',
    });
    observer.observe(backgroundParent);
};
const setupBackground = async (specs, adSlot) => {
    const { backgroundParent, background } = createParent(adSlot, specs.scrollType);
    return fastdom.mutate(() => {
        setBackgroundStyles(specs, background);
        if (specs.scrollType === 'parallax') {
            setupParallax(adSlot, background, backgroundParent);
        }
        // fixed background is very similar to interscroller, generally with a smaller height
        if (specs.scrollType === 'fixed') {
            adSlot.style.position = 'relative';
            if (isDCR) {
                background.style.position = 'fixed';
            }
            if (specs.backgroundColor) {
                backgroundParent.style.backgroundColor = specs.backgroundColor;
            }
        }
        if (specs.scrollType === 'interscroller') {
            if (isDCR) {
                adSlot.style.height = '85vh';
                adSlot.style.marginBottom = '12px';
                adSlot.style.position = 'relative';
            }
            void renderAdvertLabel(adSlot);
            void renderStickyScrollForMoreLabel(backgroundParent);
            isDCR && renderBottomLine(background, backgroundParent);
            if (specs.ctaUrl) {
                const anchor = setCtaURL(specs.ctaUrl, backgroundParent);
                adSlot.insertBefore(anchor, adSlot.firstChild);
            }
        }
        else {
            adSlot.insertBefore(backgroundParent, adSlot.firstChild);
        }
    });
};
const init = (register) => {
    register('background', async (specs, ret, iframe) => {
        if (!isBackgroundSpecs(specs)) {
            return Promise.resolve();
        }
        const adSlot = iframe?.closest('.js-ad-slot');
        if (adSlot) {
            return setupBackground(specs, adSlot);
        }
    });
};
export const _ = {
    setupBackground,
    getStylesFromSpec,
};
export { init };
