let supportsPushState;
const _window = window;
const hasPushStateSupport = () => {
    if (supportsPushState !== undefined) {
        return supportsPushState;
    }
    if (_window.history?.pushState) {
        supportsPushState = true;
        // Android stock browser lies about its HistoryAPI support.
        if (/Android/i.exec(window.navigator.userAgent)) {
            supportsPushState = !!/(Chrome|Firefox)/i.exec(window.navigator.userAgent);
        }
        return supportsPushState;
    }
    return false;
};
export { hasPushStateSupport };
