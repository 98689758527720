/**
 * This function is used to send a logging event to BigQuery, which is
 * logged into the `fastly_logging` table within the `logging` dataset.
 * @param moduleName the name of the JS/TS module (e.g. `article-body-adverts`)
 * @param functionName the name of the function within the module (e.g. `init`)
 * @param parameters an optional object to add function-specific information (e.g. `{ conditionA: 'true', conditionB: 'false' }`)
 * @returns void.
 */
export const amIUsed = (moduleName, functionName, parameters) => {
    // The function will return early if the sentinelLogger switch is disabled.
    if (!window.guardian.config.switches.sentinelLogger)
        return;
    const endpoint = window.guardian.config.page.isDev
        ? '//logs.code.dev-guardianapis.com/log'
        : '//logs.guardianapis.com/log';
    const properties = [
        { name: 'module_name', value: moduleName },
        { name: 'function_name', value: functionName },
        { name: 'URL', value: window.location.href },
    ];
    const event = {
        label: 'commercial.amiused',
        properties: parameters
            ? [
                ...properties,
                ...Object.entries(parameters).map(([name, value]) => ({
                    name,
                    value: String(value),
                })),
            ]
            : properties,
    };
    window.navigator.sendBeacon(endpoint, JSON.stringify(event));
};
