import { isString } from '@guardian/libs';
import fastdom from '../../../../lib/fastdom-promise';
const setType = (adSlotType, adSlot) => fastdom.mutate(() => {
    adSlot.classList.add(`ad-slot--${adSlotType}`);
});
const init = (register) => {
    register('type', (specs, ret, iframe) => {
        const adSlot = iframe?.closest('.js-ad-slot');
        if (adSlot && isString(specs)) {
            void setType(specs, adSlot);
        }
    });
};
export { init };
