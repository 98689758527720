import { isObject, isString } from '@guardian/libs';
import fastdom from '../../../../lib/fastdom-promise';
const isValidResizeSpecs = (specs) => {
    return (isObject(specs) &&
        (specs.width === undefined ||
            isString(specs.width) ||
            typeof specs.width === 'number') &&
        (specs.height === undefined ||
            isString(specs.height) ||
            typeof specs.height === 'number'));
};
const normalise = (length) => {
    const lengthRegexp = /^(\d+)(%|px|em|ex|ch|rem|vh|vw|vmin|vmax)?/;
    const defaultUnit = 'px';
    const matches = lengthRegexp.exec(String(length));
    if (!matches) {
        return '';
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- update tsconfig: "noUncheckedIndexedAccess": true
    return matches[1] + (matches[2] === undefined ? defaultUnit : matches[2]);
};
const resize = (specs, iframe, iframeContainer, adSlot) => {
    if (!isValidResizeSpecs(specs) || !adSlot) {
        return Promise.resolve();
    }
    const styles = {};
    if (specs.width) {
        styles.width = normalise(specs.width);
    }
    if (specs.height) {
        styles.height = normalise(specs.height);
    }
    return fastdom.mutate(() => {
        Object.assign(iframe.style, styles);
        if (iframeContainer) {
            Object.assign(iframeContainer.style, styles);
        }
    });
};
// When an outstream resizes we want it to revert to its original styling
const removeAnyOutstreamClass = (adSlot) => {
    void fastdom.mutate(() => {
        adSlot.classList.remove('ad-slot--outstream');
    });
};
const init = (register) => {
    register('resize', (specs, ret, iframe) => {
        if (iframe && specs) {
            const adSlot = iframe.closest('.js-ad-slot') ?? undefined;
            if (adSlot) {
                removeAnyOutstreamClass(adSlot);
            }
            const iframeContainer = iframe.closest('.ad-slot__content') ?? undefined;
            return resize(specs, iframe, iframeContainer, adSlot);
        }
    });
};
export const _ = { resize, normalise };
export { init };
