import { createAdSize, EventTimer, pubmatic } from '@guardian/commercial-core';
import { PREBID_TIMEOUT } from '@guardian/commercial-core/dist/esm/constants';
import { onConsent } from '@guardian/consent-management-platform';
import { isString, log } from '@guardian/libs';
import { flatten } from 'lodash-es';
import { getPageTargeting } from 'common/modules/commercial/build-page-targeting';
import { dfpEnv } from '../../dfp/dfp-env';
import { getAdvertById } from '../../dfp/get-advert-by-id';
import { getHeaderBiddingAdSlots } from '../slot-config';
import { stripDfpAdPrefixFrom } from '../utils';
import { bids } from './bid-config';
import { criteoPriceGranularity, indexPriceGranularity, ozonePriceGranularity, priceGranularity, } from './price-config';
/**
 * Prebid supports an additional timeout buffer to account for noisiness in
 * timing JavaScript on the page. This value is passed to the Prebid config
 * and is adjustable via this constant
 */
const timeoutBuffer = 400;
/**
 * The amount of time reserved for the auction
 */
const bidderTimeout = PREBID_TIMEOUT;
class PrebidAdUnit {
    code;
    bids;
    mediaTypes;
    constructor(advert, slot, pageTargeting) {
        this.code = advert.id;
        this.bids = bids(advert.id, slot.sizes, pageTargeting);
        this.mediaTypes = { banner: { sizes: slot.sizes } };
        advert.headerBiddingSizes = slot.sizes;
        log('commercial', `PrebidAdUnit ${this.code}`, this.bids);
    }
    isEmpty() {
        return this.code == null;
    }
}
let requestQueue = Promise.resolve();
let initialised = false;
const initialise = (window, framework = 'tcfv2') => {
    if (!window.pbjs) {
        log('commercial', 'window.pbjs not found on window');
        return; // We couldn’t initialise
    }
    initialised = true;
    const userSync = window.guardian.config.switches.prebidUserSync
        ? {
            syncsPerBidder: 0,
            filterSettings: {
                all: {
                    bidders: '*',
                    filter: 'include',
                },
            },
        }
        : { syncEnabled: false };
    const consentManagement = () => {
        switch (framework) {
            case 'aus':
            case 'ccpa':
                // https://docs.prebid.org/dev-docs/modules/consentManagementUsp.html
                return {
                    usp: {
                        cmpApi: 'iab',
                        timeout: 1500,
                    },
                };
            case 'tcfv2':
            default:
                // https://docs.prebid.org/dev-docs/modules/consentManagement.html
                return {
                    gdpr: {
                        cmpApi: 'iab',
                        timeout: 200,
                        defaultGdprScope: true,
                    },
                };
        }
    };
    const pbjsConfig = Object.assign({}, {
        bidderTimeout,
        timeoutBuffer,
        priceGranularity,
        userSync,
    });
    window.pbjs.bidderSettings = {};
    if (window.guardian.config.switches.consentManagement) {
        pbjsConfig.consentManagement = consentManagement();
    }
    if (window.guardian.config.switches.permutive &&
        window.guardian.config.switches.prebidPermutiveAudience) {
        pbjsConfig.realTimeData = {
            dataProviders: [
                {
                    name: 'permutive',
                    params: {
                        acBidders: ['appnexus', 'ozone', 'pubmatic', 'trustx'],
                        overwrites: {
                            pubmatic,
                        },
                    },
                },
            ],
        };
    }
    if (window.guardian.config.switches.prebidCriteo) {
        pbjsConfig.criteo = {
            fastBidVersion: 'latest',
        };
        // Use a custom price granularity for Criteo
        // Criteo has a different line item structure and so bids should be rounded to match these
        window.pbjs.setBidderConfig({
            bidders: ['criteo'],
            config: {
                customPriceBucket: criteoPriceGranularity,
            },
        });
    }
    if (window.guardian.config.switches.prebidOzone) {
        // Use a custom price granularity, which is based upon the size of the slot being auctioned
        window.pbjs.setBidderConfig({
            bidders: ['ozone'],
            config: {
                // Select the ozone granularity, use default if not defined for the size
                guCustomPriceBucket: ({ width, height }) => {
                    const ozoneGranularity = ozonePriceGranularity(width, height);
                    log('commercial', `Custom Prebid - Ozone price bucket for size (${width},${height}):`, ozoneGranularity);
                    return ozoneGranularity;
                },
            },
        });
    }
    if (window.guardian.config.switches.prebidIndexExchange) {
        window.pbjs.setBidderConfig({
            bidders: ['ix'],
            config: {
                guCustomPriceBucket: ({ width, height }) => {
                    const indexGranularity = indexPriceGranularity(width, height);
                    log('commercial', `Custom Prebid - Index price bucket for size (${width},${height}):`, indexGranularity);
                    return indexGranularity;
                },
            },
        });
    }
    if (window.guardian.config.switches.prebidAnalytics) {
        window.pbjs.enableAnalytics([
            {
                provider: 'gu',
                options: {
                    ajaxUrl: window.guardian.config.page.ajaxUrl ?? '',
                    pv: window.guardian.ophan.pageViewId,
                },
            },
        ]);
    }
    if (window.guardian.config.switches.prebidXaxis) {
        window.pbjs.bidderSettings.xhb = {
            adserverTargeting: [
                {
                    key: 'hb_buyer_id',
                    val(bidResponse) {
                        // TODO: should we return null or an empty string?
                        return bidResponse.appnexus?.buyerMemberId ?? '';
                    },
                },
            ],
            bidCpmAdjustment: (bidCpm) => {
                return bidCpm * 1.05;
            },
        };
    }
    if (window.guardian.config.switches.prebidImproveDigital) {
        // Add placement ID for Improve Digital, reading from the bid response
        const REGEX_PID = new RegExp(/placement_id=\\?"(\d+)\\?"/);
        window.pbjs.bidderSettings.improvedigital = {
            adserverTargeting: [
                {
                    key: 'hb_pid',
                    val(bidResponse) {
                        if (!isString(bidResponse.ad))
                            return undefined;
                        const matches = REGEX_PID.exec(bidResponse.ad);
                        const pid = matches?.[1];
                        return pid;
                    },
                },
            ],
            suppressEmptyKeys: true,
        };
        pbjsConfig.improvedigital = {
            usePrebidSizes: true,
        };
    }
    window.pbjs.setConfig(pbjsConfig);
    // Adjust slot size when prebid ad loads
    window.pbjs.onEvent('bidWon', (data) => {
        const { width, height, adUnitCode } = data;
        if (!width || !height || !adUnitCode) {
            return;
        }
        const size = createAdSize(width, height); // eg. [300, 250]
        const advert = getAdvertById(adUnitCode);
        if (!advert) {
            return;
        }
        advert.size = size;
        /**
         * when hasPrebidSize is true we use size
         * set here when adjusting the slot size.
         * */
        advert.hasPrebidSize = true;
    });
};
const bidsBackHandler = (adUnits, eventTimer) => new Promise((resolve) => {
    window.pbjs?.setTargetingForGPTAsync(adUnits.map((u) => u.code).filter(isString));
    resolve();
    adUnits.forEach((adUnit) => {
        if (isString(adUnit.code)) {
            eventTimer.trigger('prebidEnd', stripDfpAdPrefixFrom(adUnit.code));
        }
    });
});
// slotFlatMap allows you to dynamically interfere with the PrebidSlot definition
// for this given request for bids.
const requestBids = async (adverts, slotFlatMap) => {
    if (!initialised) {
        return requestQueue;
    }
    if (!dfpEnv.hbImpl.prebid) {
        return requestQueue;
    }
    const adUnits = await onConsent()
        .then((consentState) => {
        // calculate this once before mapping over
        const pageTargeting = getPageTargeting(consentState);
        return flatten(adverts.map((advert) => getHeaderBiddingAdSlots(advert, slotFlatMap)
            .map((slot) => new PrebidAdUnit(advert, slot, pageTargeting))
            .filter((adUnit) => !adUnit.isEmpty())));
    })
        .catch((e) => {
        // silently fail
        log('commercial', 'Failed to execute prebid onConsent', e);
        return [];
    });
    const eventTimer = EventTimer.get();
    requestQueue = requestQueue.then(() => new Promise((resolve) => {
        adUnits.forEach((adUnit) => {
            if (isString(adUnit.code)) {
                eventTimer.trigger('prebidStart', stripDfpAdPrefixFrom(adUnit.code));
            }
        });
        window.pbjs?.que.push(() => {
            window.pbjs?.requestBids({
                adUnits,
                bidsBackHandler: () => void bidsBackHandler(adUnits, eventTimer).then(resolve),
            });
        });
    }));
    return requestQueue;
};
export const prebid = { initialise, requestBids };
