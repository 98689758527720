import { flatten } from 'lodash-es';
import { noop } from 'lib/noop';
import { dfpEnv } from '../../dfp/dfp-env';
import { getHeaderBiddingAdSlots } from '../slot-config';
/*
 * Amazon's header bidding javascript library
 * https://ams.amazon.com/webpublisher/uam/docs/web-integration-documentation/integration-guide/javascript-guide/display.html
 */
class A9AdUnit {
    slotID;
    slotName;
    sizes;
    constructor(advert, slot) {
        this.slotID = advert.id;
        this.slotName = window.guardian.config.page.adUnit;
        this.sizes = slot.sizes.map((size) => Array.from(size));
    }
}
let initialised = false;
let requestQueue = Promise.resolve();
const bidderTimeout = 1500;
const initialise = () => {
    if (!initialised && window.apstag) {
        initialised = true;
        window.apstag.init({
            pubID: window.guardian.config.page.a9PublisherId,
            adServer: 'googletag',
            bidTimeout: bidderTimeout,
        });
    }
};
// slotFlatMap allows you to dynamically interfere with the PrebidSlot definition
// for this given request for bids.
const requestBids = async (adverts, slotFlatMap) => {
    if (!initialised) {
        return requestQueue;
    }
    if (!dfpEnv.hbImpl.a9) {
        return requestQueue;
    }
    const adUnits = flatten(adverts.map((advert) => getHeaderBiddingAdSlots(advert, slotFlatMap).map((slot) => new A9AdUnit(advert, slot))));
    if (adUnits.length === 0) {
        return requestQueue;
    }
    requestQueue = requestQueue
        .then(() => new Promise((resolve) => {
        window.apstag?.fetchBids({ slots: adUnits }, () => {
            window.googletag.cmd.push(() => {
                window.apstag?.setDisplayBids();
                resolve();
            });
        });
    }))
        .catch(noop);
    return requestQueue;
};
export const a9 = {
    initialise,
    requestBids,
};
export const _ = {
    resetModule: () => {
        initialised = false;
        requestQueue = Promise.resolve();
    },
};
