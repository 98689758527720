import { getCookie, storage } from '@guardian/libs';
import { mergeCalls } from 'common/modules/async-call-merger';
import { fetchJson } from '../../../../lib/fetch-json';
import { mediator } from '../../../../lib/mediator';
import { getUrlVars } from '../../../../lib/url';
import { createAuthenticationComponentEventParams } from './auth-component-event-params';
let userFromCookieCache = null;
const cookieName = 'GU_U';
const signOutCookieName = 'GU_SO';
const fbCheckKey = 'gu.id.nextFbCheck';
const idApiRoot = window.guardian.config.page.idApiUrl ?? '/ID_API_ROOT_URL_NOT_FOUND';
const profileRoot = window.guardian.config.page.idUrl ?? '/PROFILE_ROOT_ID_URL_NOT_FOUND';
mediator.emit('module:identity:api:loaded');
export const decodeBase64 = (str) => decodeURIComponent(escape(window.atob(str.replace(/-/g, '+').replace(/_/g, '/').replace(/,/g, '='))));
export const getUserFromCookie = () => {
    if (userFromCookieCache === null) {
        const cookieData = getUserCookie();
        let userData = null;
        if (cookieData) {
            userData = JSON.parse(decodeBase64(cookieData.split('.')[0]));
        }
        if (userData && cookieData) {
            const displayName = decodeURIComponent(userData[2]);
            userFromCookieCache = {
                id: parseInt(userData[0], 10),
                primaryEmailAddress: userData[1],
                publicFields: {
                    displayName,
                },
                dates: { accountCreatedDate: userData[6] },
                statusFields: {
                    userEmailValidated: Boolean(userData[7]),
                },
                rawResponse: cookieData,
            };
        }
    }
    return userFromCookieCache;
};
export const updateNewsletter = (newsletter) => {
    const url = `${idApiRoot}/users/me/newsletters`;
    return fetch(url, {
        method: 'PATCH',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify(newsletter),
    }).then(() => Promise.resolve());
};
export const buildNewsletterUpdatePayload = (action = 'none', newsletterId) => {
    const newsletter = { id: newsletterId };
    switch (action) {
        case 'add':
            newsletter.subscribed = true;
            break;
        case 'remove':
            newsletter.subscribed = false;
            break;
        default:
            throw new Error(`Undefined newsletter action type (${action})`);
    }
    return newsletter;
};
export const isUserLoggedIn = () => getUserFromCookie() !== null;
export const getUserFromApi = mergeCalls((mergingCallback) => {
    if (isUserLoggedIn()) {
        const url = `${idApiRoot}/user/me`;
        void fetchJson(url, {
            mode: 'cors',
            credentials: 'include',
        }) // assert unknown -> IdentityResponse
            .then((data) => {
            if (data.status === 'ok') {
                mergingCallback(data.user);
            }
            else {
                mergingCallback(null);
            }
        });
    }
    else {
        mergingCallback(null);
    }
});
const fetchUserIdentifiers = () => {
    const url = `${idApiRoot}/user/me/identifiers`;
    return fetch(url, {
        mode: 'cors',
        credentials: 'include',
    })
        .then((resp) => {
        if (resp.status === 200) {
            return resp.json();
        }
        else {
            console.log('failed to get Identity user identifiers', resp.status);
            return null;
        }
    })
        .catch((e) => {
        console.log('failed to get Identity user identifiers', e);
        return null;
    });
};
export const getUserIdentifiersFromApi = mergeCalls((mergingCallback) => {
    if (isUserLoggedIn()) {
        void fetchUserIdentifiers().then((result) => mergingCallback(result));
    }
    else {
        mergingCallback(null);
    }
});
export const reset = () => {
    getUserFromApi.reset();
    getUserIdentifiersFromApi.reset();
    userFromCookieCache = null;
};
const getUserCookie = () => getCookie({ name: cookieName });
export const getUrl = () => profileRoot;
export const getUserFromApiWithRefreshedCookie = () => {
    const endpoint = `${idApiRoot}/user/me?refreshCookie=true`;
    return fetch(endpoint, {
        mode: 'cors',
        credentials: 'include',
    }).then((resp) => resp.json());
};
export const refreshOktaSession = (returnUrl) => {
    const endpoint = `${profileRoot}/signin/refresh?returnUrl=${returnUrl}`;
    window.location.replace(endpoint);
};
export const redirectTo = (url) => {
    window.location.assign(url);
};
export const getUserOrSignIn = (componentId, paramUrl) => {
    let returnUrl = paramUrl;
    if (isUserLoggedIn()) {
        return getUserFromCookie();
    }
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- not sure what it would do
    returnUrl = encodeURIComponent(returnUrl || document.location.href);
    const url = [
        getUrl() || '',
        '/signin?returnUrl=',
        returnUrl,
        '&',
        createAuthenticationComponentEventParams(componentId),
    ].join('');
    redirectTo(url);
};
export const hasUserSignedOutInTheLast24Hours = () => {
    const cookieData = getCookie({
        name: signOutCookieName,
    });
    if (cookieData) {
        return (Math.round(new Date().getTime() / 1000) <
            parseInt(cookieData, 10) + 86400);
    }
    return false;
};
export const shouldAutoSigninInUser = () => {
    const signedInUser = !!getUserCookie();
    const checkFacebook = !!storage.local.get(fbCheckKey);
    return (!signedInUser && !checkFacebook && !hasUserSignedOutInTheLast24Hours());
};
export const sendValidationEmail = () => {
    const defaultReturnEndpoint = '/email-prefs';
    const endpoint = `${idApiRoot}/user/send-validation-email`;
    const returnUrlVar = getUrlVars().returnUrl;
    const returnUrl = typeof returnUrlVar === 'string'
        ? decodeURIComponent(returnUrlVar)
        : profileRoot + defaultReturnEndpoint;
    const params = new URLSearchParams();
    params.append('returnUrl', returnUrl);
    const request = fetch(`${endpoint}?${params.toString()}`, {
        mode: 'cors',
        credentials: 'include',
        method: 'POST',
    });
    return request;
};
export const updateUsername = (username) => {
    const endpoint = `${idApiRoot}/user/me`;
    const data = {
        publicFields: {
            username,
            displayName: username,
        },
    };
    const request = fetch(endpoint, {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'include',
    });
    return request;
};
export const setConsent = (consents) => fetch(`${idApiRoot}/users/me/consents`, {
    method: 'PATCH',
    credentials: 'include',
    mode: 'cors',
    body: JSON.stringify(consents),
}).then((resp) => {
    if (resp.ok)
        return Promise.resolve();
    return Promise.reject();
});
export { getUserCookie as getCookie };
